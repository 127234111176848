/*
    As the yet-another-react-lightbox library is using typescript 5.4.0 and currently in our projec the version is 3.8, importing the css directly is not working. 
    So, we have to copy the css from the library and paste it in our project, to make it functional.
    TODO: As soon as we upgrade the typescript version, we can remove this css file and import the library css directly.
*/
/* stylelint-disable */
.yarl__fullsize {
  height: 100%;
  width: 100%;
}

.yarl__relative {
  position: relative;
}

.yarl__portal {
  bottom: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity var(--yarl__fade_animation_duration, 0.25s)
    var(--yarl__fade_animation_timing_function, ease);
  z-index: var(--yarl__portal_zindex, 9999);
}

.yarl__portal_open {
  opacity: 1;
}

.yarl__container {
  background-color: var(
    --yarl__container_background_color,
    var(--yarl__color_backdrop, #000)
  );
  bottom: 0;
  left: 0;
  outline: none;
  overflow: hidden;
  overscroll-behavior: var(--yarl__controller_overscroll_behavior, contain);
  position: absolute;
  right: 0;
  top: 0;
  touch-action: var(--yarl__controller_touch_action, none);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.yarl__carousel {
  align-content: center;
  align-items: stretch;
  display: flex;
  flex: 0 0 auto;
  height: 100%;
  justify-content: center;
  opacity: var(--yarl__pull_opacity, 1);
  transform: translate(
    var(--yarl__swipe_offset, 0),
    var(--yarl__pull_offset, 0)
  );
  width: calc(
    100% + (var(--yarl__carousel_slides_count) - 1) *
      (
        100% + var(--yarl__carousel_spacing_px, 0) * 1px +
          var(--yarl__carousel_spacing_percent, 0) * 1%
      )
  );
}

.yarl__carousel_with_slides {
  -moz-column-gap: calc(
    var(--yarl__carousel_spacing_px, 0) * 1px + 100 /
      (
        var(--yarl__carousel_slides_count) * 100 +
          (var(--yarl__carousel_slides_count) - 1) *
          var(--yarl__carousel_spacing_percent, 0)
      ) * var(--yarl__carousel_spacing_percent, 0) * 1%
  );
  column-gap: calc(
    var(--yarl__carousel_spacing_px, 0) * 1px + 100 /
      (
        var(--yarl__carousel_slides_count) * 100 +
          (var(--yarl__carousel_slides_count) - 1) *
          var(--yarl__carousel_spacing_percent, 0)
      ) * var(--yarl__carousel_spacing_percent, 0) * 1%
  );
}

.yarl__flex_center {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.yarl__slide {
  flex: 1;
  overflow: hidden;
  padding: calc(
    var(--yarl__carousel_padding_px, 0) * 1px + 100 /
      (
        var(--yarl__carousel_slides_count) * 100 +
          (var(--yarl__carousel_slides_count) - 1) *
          var(--yarl__carousel_spacing_percent, 0)
      ) * var(--yarl__carousel_padding_percent, 0) * 1%
  );
  position: relative;
}

[dir='rtl'] .yarl__slide {
  --yarl__direction: -1;
}

.yarl__slide_image {
  max-height: 100%;
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  touch-action: var(--yarl__controller_touch_action, none);
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.yarl__slide_image_cover {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.yarl__slide_image_loading {
  opacity: 0;
}

@media screen and (min-width: 800px) {
  .yarl__slide_wrapper:not(.yarl__slide_wrapper_interactive)
    .yarl__slide_image {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -webkit-transform-style: preserve-3d;
  }
}

.yarl__slide_placeholder {
  left: 50%;
  line-height: 0;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.yarl__slide_loading {
  animation: yarl__delayed_fadein 1s linear;
  color: var(
    --yarl__slide_icon_loading_color,
    var(--yarl__color_button, hsla(0, 0%, 100%, 0.8))
  );
}

.yarl__slide_loading line {
  animation: yarl__stroke_opacity 1s linear infinite;
}

.yarl__slide_loading line:first-of-type {
  animation-delay: -1.875s;
}

.yarl__slide_loading line:nth-of-type(2) {
  animation-delay: -1.75s;
}

.yarl__slide_loading line:nth-of-type(3) {
  animation-delay: -1.625s;
}

.yarl__slide_loading line:nth-of-type(4) {
  animation-delay: -1.5s;
}

.yarl__slide_loading line:nth-of-type(5) {
  animation-delay: -1.375s;
}

.yarl__slide_loading line:nth-of-type(6) {
  animation-delay: -1.25s;
}

.yarl__slide_loading line:nth-of-type(7) {
  animation-delay: -1.125s;
}

.yarl__slide_loading line:nth-of-type(8) {
  animation-delay: -1s;
}

.yarl__slide_error {
  color: var(--yarl__slide_icon_error_color, red);
  height: var(--yarl__slide_icon_error_size, 48px);
  width: var(--yarl__slide_icon_error_size, 48px);
}

@media (prefers-reduced-motion) {
  .yarl__portal,
  .yarl__slide {
    transition: unset;
  }

  .yarl__slide_loading,
  .yarl__slide_loading line {
    animation: unset;
  }
}

.yarl__toolbar {
  bottom: auto;
  display: flex;
  justify-content: flex-end;
  left: auto;
  padding: var(--yarl__toolbar_padding, 8px);
  position: absolute;
  right: 0;
  top: 0;
}

[dir='rtl'] .yarl__toolbar {
  bottom: auto;
  left: 0;
  right: auto;
  top: 0;
}

.yarl__icon {
  height: var(--yarl__icon_size, 32px);
  width: var(--yarl__icon_size, 32px);
}

.yarl__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--yarl__button_background_color, transparent);
  border: var(--yarl__button_border, 0);
  color: var(--yarl__color_button, hsla(0, 0%, 100%, 0.8));
  cursor: pointer;
  filter: var(
    --yarl__button_filter,
    drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8))
  );
  line-height: 0;
  margin: var(--yarl__button_margin, 0);
  outline: none;
  padding: var(--yarl__button_padding, 8px);
  -webkit-tap-highlight-color: transparent;
}

.yarl__button:focus {
  color: var(--yarl__color_button_active, #fff);
}

.yarl__button:focus:not(:focus-visible) {
  color: var(--yarl__color_button, hsla(0, 0%, 100%, 0.8));
}

.yarl__button:focus-visible {
  color: var(--yarl__color_button_active, #fff);
}

@media (hover: hover) {
  .yarl__button:focus-visible:hover,
  .yarl__button:focus:hover,
  .yarl__button:hover {
    color: var(--yarl__color_button_active, #fff);
  }
}

.yarl__button:disabled {
  color: var(--yarl__color_button_disabled, hsla(0, 0%, 100%, 0.4));
  cursor: default;
}

.yarl__navigation_next,
.yarl__navigation_prev {
  padding: var(--yarl__navigation_button_padding, 24px 16px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.yarl__navigation_prev {
  left: 0;
}

[dir='rtl'] .yarl__navigation_prev {
  left: unset;
  right: 0;
  transform: translateY(-50%) rotate(180deg);
}

.yarl__navigation_next {
  right: 0;
}

[dir='rtl'] .yarl__navigation_next {
  left: 0;
  right: unset;
  transform: translateY(-50%) rotate(180deg);
}

.yarl__no_scroll {
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}

@keyframes yarl__delayed_fadein {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes yarl__stroke_opacity {
  0% {
    stroke-opacity: 1;
  }

  to {
    stroke-opacity: 0.125;
  }
}

.yarl__slide_captions_container {
  background: var(
    --yarl__slide_captions_container_background,
    rgba(0, 0, 0, 0.5)
  );
  left: var(--yarl__slide_captions_container_left, 0);
  padding: var(--yarl__slide_captions_container_padding, 16px);
  position: absolute;
  right: var(--yarl__slide_captions_container_right, 0);
  -webkit-transform: translateZ(0);
}
.yarl__slide_title {
  color: var(--yarl__slide_title_color, #fff);
  font-size: var(--yarl__slide_title_font_size, 125%);
  font-weight: var(--yarl__slide_title_font_weight, bolder);
  max-width: calc(100% - var(--yarl__toolbar_width, 0px));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.yarl__slide_title_container {
  top: var(--yarl__slide_title_container_top, 0);
}
.yarl__slide_description {
  display: -webkit-box;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--yarl__slide_description_max_lines, 3);
  color: var(--yarl__slide_description_color, #fff);
  text-align: var(--yarl__slide_description_text_align, start);
}
.yarl__slide_description_container {
  bottom: var(--yarl__slide_description_container_bottom, 0);
}

.yarl__counter {
  bottom: var(--yarl__counter_bottom, unset);
  color: var(
    --yarl__counter_color,
    var(--yarl__color_button, hsla(0, 0%, 100%, 0.8))
  );
  filter: var(
    --yarl__counter_filter,
    drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8))
  );
  left: var(--yarl__counter_left, 0);
  line-height: var(--yarl__counter_line_height, var(--yarl__icon_size, 32px));
  margin: var(--yarl__counter_margin, var(--yarl__toolbar_padding, 8px));
  padding: var(--yarl__counter_padding, var(--yarl__button_padding, 8px));
  position: var(--yarl__counter_position, absolute);
  right: var(--yarl__counter_right, unset);
  top: var(--yarl__counter_top, 0);
  -webkit-user-select: var(--yarl__counter_user_select, none);
  -moz-user-select: var(--yarl__counter_user_select, none);
  user-select: var(--yarl__counter_user_select, none);
}

.yarl__thumbnails {
  display: flex;
  height: 100%;
}
.yarl__thumbnails_bottom,
.yarl__thumbnails_end .yarl__thumbnails_track,
.yarl__thumbnails_start .yarl__thumbnails_track,
.yarl__thumbnails_top {
  flex-direction: column;
}
.yarl__thumbnails_wrapper {
  flex: 1;
  position: relative;
}
.yarl__thumbnails_container {
  background-color: var(
    --yarl__thumbnails_container_background_color,
    var(--yarl__color_backdrop, #000)
  );
  flex: 0 0 auto;
  overflow: hidden;
  padding: var(--yarl__thumbnails_container_padding, 16px);
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.yarl__thumbnails_vignette {
  pointer-events: none;
  position: absolute;
  --yarl__thumbnails_vignette_size: 12%;
}
@media (min-width: 1200px) {
  .yarl__thumbnails_vignette {
    --yarl__thumbnails_vignette_size: 8%;
  }
}
@media (min-width: 2000px) {
  .yarl__thumbnails_vignette {
    --yarl__thumbnails_vignette_size: 5%;
  }
}
.yarl__thumbnails_bottom .yarl__thumbnails_vignette,
.yarl__thumbnails_top .yarl__thumbnails_vignette {
  background: linear-gradient(
    to right,
    var(--yarl__color_backdrop, #000) 0,
    transparent var(--yarl__thumbnails_vignette_size, 12%)
      calc(100% - var(--yarl__thumbnails_vignette_size, 12%)),
    var(--yarl__color_backdrop, #000) 100%
  );
  height: 100%;
  left: 0;
  right: 0;
}
.yarl__thumbnails_end .yarl__thumbnails_vignette,
.yarl__thumbnails_start .yarl__thumbnails_vignette {
  background: linear-gradient(
    to bottom,
    var(--yarl__color_backdrop, #000) 0,
    transparent var(--yarl__thumbnails_vignette_size, 12%)
      calc(100% - var(--yarl__thumbnails_vignette_size, 12%)),
    var(--yarl__color_backdrop, #000) 100%
  );
  bottom: 0;
  top: 0;
  width: 100%;
}
.yarl__thumbnails_track {
  gap: var(--yarl__thumbnails_thumbnail_gap, 16px);
  outline: none;
}
.yarl__thumbnails_thumbnail {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: var(--yarl__thumbnails_thumbnail_background, #000);
  border-color: var(
    --yarl__thumbnails_thumbnail_border_color,
    var(--yarl__color_button, hsla(0, 0%, 100%, 0.8))
  );
  border-radius: var(--yarl__thumbnails_thumbnail_border_radius, 4px);
  border-style: var(--yarl__thumbnails_thumbnail_border_style, solid);
  border-width: var(--yarl__thumbnails_thumbnail_border, 1px);
  cursor: pointer;
  flex: 0 0 auto;
  -webkit-tap-highlight-color: transparent;
  box-sizing: content-box;
  height: var(--yarl__thumbnails_thumbnail_height, 80px);
  outline: none;
  overflow: hidden;
  padding: var(--yarl__thumbnails_thumbnail_padding, 4px);
  position: relative;
  width: var(--yarl__thumbnails_thumbnail_width, 120px);
}
.yarl__thumbnails_thumbnail_active {
  border-color: var(
    --yarl__thumbnails_thumbnail_active_border_color,
    var(--yarl__color_button_active, #fff)
  );
}
.yarl__thumbnails_thumbnail_fadein {
  animation: yarl__thumbnails_thumbnail_fadein
    var(--yarl__thumbnails_thumbnail_fadein_duration, 0.5s) ease-in-out
    var(--yarl__thumbnails_thumbnail_fadein_delay, 0s) forwards;
  opacity: 0;
}
.yarl__thumbnails_thumbnail_fadeout {
  animation: yarl__thumbnails_thumbnail_fadeout
    var(--yarl__thumbnails_thumbnail_fadeout_duration, 0.5s) ease-in-out
    var(--yarl__thumbnails_thumbnail_fadeout_delay, 0s) forwards;
  cursor: unset;
}
.yarl__thumbnails_thumbnail_placeholder {
  cursor: unset;
  visibility: hidden;
}
.yarl__thumbnails_thumbnail:focus {
  box-shadow: var(
    --yarl__thumbnails_thumbnail_focus_box_shadow,
    #000 0 0 0 2px,
    var(--yarl__color_button, hsla(0, 0%, 100%, 0.8)) 0 0 0 4px
  );
}
.yarl__thumbnails_thumbnail:focus:not(:focus-visible) {
  box-shadow: unset;
}
.yarl__thumbnails_thumbnail:focus-visible {
  box-shadow: var(
    --yarl__thumbnails_thumbnail_focus_box_shadow,
    #000 0 0 0 2px,
    var(--yarl__color_button, hsla(0, 0%, 100%, 0.8)) 0 0 0 4px
  );
}
.yarl__thumbnails_thumbnail_icon {
  color: var(
    --yarl__thumbnails_thumbnail_icon_color,
    var(--yarl__color_button, hsla(0, 0%, 100%, 0.8))
  );
  filter: var(
    --yarl__thumbnails_thumbnail_icon_filter,
    drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8))
  );
  height: var(--yarl__thumbnails_thumbnail_icon_size, 32px);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: var(--yarl__thumbnails_thumbnail_icon_size, 32px);
}
@keyframes yarl__thumbnails_thumbnail_fadein {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes yarl__thumbnails_thumbnail_fadeout {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* stylelint-disable */
